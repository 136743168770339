/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { format } from 'timeago.js';
import classNames from 'classnames';

import NotificationButtons from './NotificationButtons';

import PublicService from '../../../services/PublicService';
import useGetNotificationData from '../../../hooks/useGetNotificationData';
import classes from './styles.module.scss';

export default function Notification({
  notification,
  showJobApplicationInfo,
  refetch,
  showMessages,
}) {
  const notificationData = useGetNotificationData(
    notification,
    refetch,
    showMessages
  );

  const markNotificationAsRead = async () => {
    try {
      await PublicService.readNotification(notification.id);
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteNotification = async () => {
    try {
      await PublicService.deleteNotification(notification.id);
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <li
      onClick={() => {
        markNotificationAsRead();

        if (!notification.jobApplication) {
          return;
        }

        showJobApplicationInfo(notification.jobApplication);
      }}
      className={classNames(classes.Notification, {
        [classes.read]: !!notification.readAt,
      })}
    >
      {notification?.readAt ? (
        <button
          className={classes.deleteButton}
          type="button"
          onClick={(event) => {
            event.stopPropagation();
            deleteNotification();
          }}
        >
          <svg
            width="7"
            height="7"
            viewBox="0 0 7 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.24526 3.74998L6.51828 6.02284C6.68452 6.18907 6.68452 6.45858 6.51828 6.62481C6.35204 6.79104 6.08251 6.79104 5.91627 6.62481L3.64325 4.35194L1.37009 6.62494C1.20385 6.79117 0.934324 6.79117 0.768085 6.62494C0.601845 6.45872 0.601845 6.18921 0.768085 6.02298L3.04124 3.74998L0.767746 1.47664C0.601507 1.31041 0.601507 1.0409 0.767746 0.874671C0.933985 0.708443 1.20351 0.708443 1.36975 0.874671L3.64325 3.14801L5.91661 0.874807C6.08285 0.708579 6.35238 0.708579 6.51862 0.874807C6.68486 1.04104 6.68486 1.31054 6.51862 1.47677L4.24526 3.74998Z"
              fill="white"
            />
          </svg>
        </button>
      ) : (
        <div className={classes.unreadMark} />
      )}
      <h4 className={classes.title}>{notificationData.title}</h4>
      <div className={classes.text}>
        {notificationData.text}.
        <span className={classes.createdAt}>
          {' '}
          {format(notification.createdAt)}
        </span>
      </div>
      {!notificationData.isOutdated && notificationData.buttonsData && (
        <div className={classes.buttons}>
          <NotificationButtons
            buttonsData={notificationData.buttonsData}
            markNotificationAsRead={markNotificationAsRead}
          />
        </div>
      )}
    </li>
  );
}
