import React from 'react';
import { Navigate } from 'react-router-dom';

import { hasAuthData } from '../../helpers/authStorage';

export default function PrivateRoute({ children }) {
  const isAuthenticated = hasAuthData();

  return isAuthenticated ? children : <Navigate to="/?signin=true" />;
}
