import React, { useState, useEffect, useRef } from 'react';

import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import '../../../../../Calendar/calendar.scss';
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function JobDate({
  value,
  onChange,
  tileDisabled,
  label,
  alignRight,
}) {
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [coords, setCoords] = useState(null);

  const startDateCalendarRef = useRef();
  const selectorRef = useRef();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  useOnClickOutside(startDateCalendarRef, () => setIsCalendarVisible(false));

  useEffect(() => {
    setIsCalendarVisible(false);
  }, [value]);

  useEffect(() => {
    if (!isCalendarVisible) {
      setCoords(null);
    }
  }, [isCalendarVisible]);

  useEffect(() => {
    if (isCalendarVisible) {
      const rect = selectorRef.current.getBoundingClientRect();
      setCoords({
        bottom: rect.bottom,
        top: rect.top,
        left: alignRight
          ? rect.left - (458 - selectorRef.current.offsetWidth)
          : rect.left - 200,
      });
    }
  }, [isCalendarVisible, alignRight]);

  return (
    <div className={classes.JobDate}>
      <h2 className={classes.label}>{label}</h2>
      <div
        className={classes.date}
        ref={selectorRef}
        onClick={() => setIsCalendarVisible(true)}
      >
        {value ? (
          moment(value).format('DD MMM YYYY')
        ) : (
          <span className={classes.placeholder}>DD MMM YYYY</span>
        )}
      </div>
      {isCalendarVisible && (
        <div
          onClick={(event) => {
            if (isTabletOrMobile && event.target === event.currentTarget) {
              setIsCalendarVisible(false);
            }
          }}
          className={classNames(classes.calendarContainer, classes.startDate)}
          ref={startDateCalendarRef}
          style={
            isTabletOrMobile
              ? null
              : {
                  opacity: coords ? 1 : 0,
                  position: coords ? 'fixed' : '',
                  left: coords ? coords.left : '',
                  top: coords ? coords.top - 270 : '',
                }
          }
        >
          <div className={classes.innerContainer}>
            <Calendar
              className={classNames(classes.calendar, 'calendarComponent')}
              defaultValue={new Date()}
              value={value ? new Date(value) : null}
              onChange={onChange}
              tileDisabled={tileDisabled}
            />
          </div>
        </div>
      )}
    </div>
  );
}
