import { useQueryClient } from '@tanstack/react-query';
import useUiContext from './useUiContext';

import { clearAuthData, hasAuthData } from '../helpers/authStorage';
import UserService from '../services/UserService';

/**
 * A custom React hook that checks if the current user is blocked and logs them out if they are.
 * @returns {Object} An object containing a function to check and logout the user.
 */
export default function useLogoutBlockedUser() {
  const queryClient = useQueryClient();

  const { setIsAuthenticated } = useUiContext();

  return {
    /**
     * Checks if the current user is blocked and logs them out if they are.
     * @returns {Promise<void>} A promise that resolves when the user is logged out.
     */
    checkAndLogout: async () => {
      try {
        const isAuth = hasAuthData();

        if (!isAuth) {
          return;
        }

        const user = await UserService.getMe();

        if (user?.isBlocked) {
          clearAuthData();
          setIsAuthenticated(false);
          queryClient.invalidateQueries();
        }
      } catch (error) {
        console.log(error);
      }
    },
  };
}
