/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import 'react-calendar/dist/Calendar.css';

import JobDate from './JobDate';

import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function Job({ job, changeJob, deleteJob, endDate }) {
  const [, setIsEndDateCalendarVisible] = useState(false);
  const [, setIsStartDateCalendarVisible] = useState(false);

  const { t } = useTranslation();

  const endDateCalendarRef = useRef();
  useOnClickOutside(endDateCalendarRef, () =>
    setIsEndDateCalendarVisible(false)
  );

  const startDateCalendarRef = useRef();
  useOnClickOutside(startDateCalendarRef, () =>
    setIsStartDateCalendarVisible(false)
  );

  const changeEndDate = (value) => {
    changeJob(job.id, 'endDate', value);
    setIsEndDateCalendarVisible(false);
  };

  const changeStartDate = (value) => {
    changeJob(job.id, 'startDate', value);
    setIsStartDateCalendarVisible(false);
  };

  return (
    <div
      className={classes.Job}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <div className={classes.textInputContainer}>
        <div className={classes.jobTitleHeader}>
          <label htmlFor="jobTitle">
            {t(
              'components.RecordVideo.AdditionalInfoForm.WorkExperienceDropdown.Job.jobTitleLabel'
            )}
          </label>
          <button
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              deleteJob(job.id);
            }}
          >
            {t('common.delete')}
          </button>
        </div>
        <input
          placeholder={t(
            'components.RecordVideo.AdditionalInfoForm.WorkExperienceDropdown.Job.jobTitlePlaceholder'
          )}
          type="text"
          id="jobTitle"
          value={job.jobTitle}
          onChange={(event) => {
            changeJob(job.id, 'jobTitle', event.target.value);
          }}
        />
      </div>
      <div className={classes.textInputContainer}>
        <label htmlFor="companyName">
          {t(
            'components.RecordVideo.AdditionalInfoForm.WorkExperienceDropdown.Job.companyNameLabel'
          )}
        </label>
        <input
          placeholder={t(
            'components.RecordVideo.AdditionalInfoForm.WorkExperienceDropdown.Job.companyNamePlaceholder'
          )}
          type="text"
          id="companyName"
          value={job.companyName}
          onChange={(event) =>
            changeJob(job.id, 'companyName', event.target.value)
          }
        />
      </div>
      <div className={classes.dates}>
        <JobDate
          alignRight
          value={job.startDate ? new Date(job.startDate) : null}
          onChange={changeStartDate}
          tileDisabled={({ date }) => {
            if (date > new Date()) {
              return true;
            }

            if (endDate) {
              return new Date(date) > new Date(endDate) - 1;
            }
            return false;
          }}
          label={t(
            'components.RecordVideo.AdditionalInfoForm.WorkExperienceDropdown.Job.startDateLabel'
          )}
        />
        <JobDate
          alignRight
          value={job.endDate ? new Date(job.endDate) : null}
          onChange={changeEndDate}
          tileDisabled={({ date }) => {
            if (date > new Date()) {
              return true;
            }

            if (job.startDate) {
              return new Date(date) - 1 < new Date(job.startDate);
            }
            return false;
          }}
          label={t(
            'components.RecordVideo.AdditionalInfoForm.WorkExperienceDropdown.Job.endDateLabel'
          )}
        />
      </div>
    </div>
  );
}
