import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import Loader from '../../components/Loader';
import NavHeader from '../../components/NavHeader';
import CityCard from '../../components/CityCard';

import classes from './styles.module.scss';
import AnonymousService from '../../services/AnonymousService';

export default function CityJobsPage() {
  const { data: cities, isFetching } = useQuery({
    queryKey: ['cities'],
    queryFn: AnonymousService.getCities,
  });

  const jobsPageRef = useRef();

  const { t } = useTranslation();

  return (
    <div className={classes.CityJobsPage} ref={jobsPageRef}>
      {isFetching && !cities && <Loader />}
      <div className={classes.header}>
        <h1>{t('pages.CityJobsPage.excitedText')}</h1>
        <p className={classes.info}>
          {t('pages.CityJobsPage.pickLocationStartText')}{' '}
          <span>{t('pages.CityJobsPage.pickLocationSpanText')}</span>{' '}
          {t('pages.CityJobsPage.pickLocationEndText')}
        </p>
        <NavHeader />
      </div>
      <div className={classes.cities}>
        {cities
          ?.sort((a, b) => b.jobPostCount - a.jobPostCount)
          .map((city) => (
            <CityCard key={city.id} city={city} />
          ))}
      </div>
    </div>
  );
}
