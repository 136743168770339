import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import parsePhoneNumber from 'libphonenumber-js';
import 'bootstrap/dist/css/bootstrap.min.css';

import Loader from '../../components/Loader';
import ProfileForm from '../../components/SignIn/ProfileForm';
import OtpVerificationForm from '../../components/SignIn/OtpVerificationForm';
import EmailForm from '../../components/SignIn/EmailForm';

import { saveAuthData } from '../../helpers/authStorage';
import { UiContext } from '../../context/UiContext';
import UserService from '../../services/UserService';
import classes from './styles.module.scss';

export default function SignInModal({ show, handleClose, profile }) {
  const [currentForm, setCurrentForm] = useState('email');
  const [hasEmailFormTriedToSubmit, setHasEmailFormTriedToSubmit] =
    useState(false);
  const [hasProfileFormTriedToSubmit, setHasProfileFormTriedToSubmit] =
    useState(false);
  const [otp, setOtp] = useState('');
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const {
    showUnknownErrorModal,
    showModal,
    checkIfUserProfileFilled,
    setIsAuthenticated,
  } = useContext(UiContext);

  const { t } = useTranslation();

  const { data: user, refetch } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
    enabled: hasLoggedIn,
  });

  const emailValidationSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .trim()
          .email(t('modals.SignInModal.validation.email'))
          .required(t('common.requiredField')),
      }),
    [t]
  );

  const signupValidationSchema = useMemo(
    () =>
      yup.object({
        firstName: yup
          .string()
          .trim()
          .required(t('modals.SignInModal.validation.firstName')),
        lastName: yup
          .string()
          .trim()
          .required(t('modals.SignInModal.validation.lastName')),
        phone: yup
          .string()
          /* .test('validatePhone', 'Phone number is not valid', (value) => {
        return new Promise((resolve) => {
          import('react-phone-number-input')
            .then(({ isValidPhoneNumber }) => {
              const isValidPhone = isValidPhoneNumber(
                value.startsWith('+') ? `${value}` : `+${value}`
              );
              if (isValidPhone) {
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch(() => {
              resolve(false);
            });
        });
      }) */
          // .matches(
          //   // eslint-disable-next-line no-useless-escape
          //   /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
          //   'Phone number is not valid'
          // )
          .test(
            'validatePhone',
            t('modals.SignInModal.validation.phone'),
            (value) => {
              try {
                const parsedNumber = parsePhoneNumber(value);
                return parsedNumber.isValid();
              } catch (error) {
                return false;
              }
            }
          )
          .required(t('modals.SignInModal.validation.phoneRequired')),
      }),
    [t]
  );

  const emailFormik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: emailValidationSchema,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);
        await UserService.requestOtp({ email: values.email });
        setCurrentForm('otp');
      } catch (error) {
        console.log(error);
        if (error.response.data.message === 'Deactivated') {
          showModal({
            title: t('pages.SignInPage.inactiveTitle'),
            text: t('pages.SignInPage.inactiveMessage'),
          });
        } else {
          showUnknownErrorModal();
        }
      } finally {
        setIsFetching(false);
      }
    },
  });

  const profileFormik = useFormik({
    initialValues: {
      firstName: user?.userProfile?.firstName || '',
      lastName: user?.userProfile?.lastName || '',
      phone: user?.userProfile?.phone || '+90',
      email: emailFormik.values?.email,
    },
    validationSchema: signupValidationSchema,
    onSubmit: async (values) => {
      try {
        await UserService.updateMe({
          firstName: values?.firstName,
          lastName: values?.lastName,
          phone: values.phone,
          email: emailFormik.values?.email,
        });
        await refetch();
        handleClose();
      } catch (error) {
        showUnknownErrorModal();
      }
    },
    enableReinitialize: true,
  });

  const resendOtp = async () => {
    try {
      setOtp('');
      await UserService.requestOtp({ email: emailFormik.values.email });

      showModal({
        title: t('modals.SignInModal.codeSent.title'),
        text: t('modals.SignInModal.codeSent.text'),
      });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    }
  };

  const resetEverything = useCallback(() => {
    emailFormik.resetForm();
    profileFormik.resetForm();
    setCurrentForm(profile ? 'profile' : 'email');
    setOtp('');
    setHasEmailFormTriedToSubmit(false);
    setHasProfileFormTriedToSubmit(false);
    setHasLoggedIn(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const submitEmailForm = (event) => {
    event.preventDefault();
    setHasEmailFormTriedToSubmit(true);
    emailFormik.handleSubmit();
  };

  const submitOtpPassword = async () => {
    try {
      if (otp?.length < 4) {
        return;
      }
      const authData = await UserService.loginByOtp({
        email: emailFormik.values.email,
        code: otp,
      });
      saveAuthData({
        accessToken: authData.token,
        userName: authData.userName,
        userId: authData.id,
      });
      setHasLoggedIn(true);
      setIsAuthenticated(true);
    } catch (error) {
      if (error.response?.status === 401) {
        showModal({
          title: t('modals.SignInModal.incorrectOTP.title'),
          text: t('modals.SignInModal.incorrectOTP.text'),
        });
      } else if (
        error.response.data?.message === 'Expireded' &&
        error.response?.status === 400
      ) {
        showModal({
          title: t('modals.SignInModal.expiredOTP.title'),
          text: t('modals.SignInModal.expiredOTP.text'),
          onCancel: () => setOtp(''),
        });
      } else {
        showUnknownErrorModal();
      }
    }
  };

  const submitProfileForm = () => {
    setHasProfileFormTriedToSubmit(true);
    profileFormik.handleSubmit();
  };

  useEffect(() => {
    if (!show) {
      resetEverything();
    }
  }, [resetEverything, show]);

  useEffect(() => {
    if (user && hasLoggedIn) {
      const isProfileComplete = checkIfUserProfileFilled(user);

      if (isProfileComplete) {
        handleClose();
      } else {
        setCurrentForm('profile');
      }
    }
  }, [handleClose, user, hasLoggedIn, checkIfUserProfileFilled]);

  useEffect(() => {
    if (profile) {
      setCurrentForm('profile');
    }
  }, [profile]);

  return (
    <Modal
      show={show}
      centered
      className={classes.Modal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        {isFetching && <Loader relative width="40%" />}
        <button
          type="button"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 1L1 13"
              stroke="#242833"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 1L13 13"
              stroke="#242833"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className={classes.formContainer}>
          {currentForm === 'email' && (
            <EmailForm
              formik={emailFormik}
              onSubmit={submitEmailForm}
              hasTriedToSubmit={hasEmailFormTriedToSubmit}
            />
          )}
          {currentForm === 'otp' && (
            <OtpVerificationForm
              otp={otp}
              setOtp={setOtp}
              onSubmit={submitOtpPassword}
              resendOtp={resendOtp}
              email={emailFormik.values.email}
            />
          )}
          {currentForm === 'profile' && (
            <ProfileForm
              formik={profileFormik}
              hasTriedToSubmit={hasProfileFormTriedToSubmit}
              onSubmit={submitProfileForm}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}
