import React, { useState, useEffect, useMemo } from 'react';

import { useMediaQuery } from 'react-responsive';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Dropdown from '../Dropdown';
import Button from '../Button';
import TextInput from '../TextInput';
import NavHeader from '../NavHeader';
import Layout from '../../containers/Layout';

import useUiContext from '../../hooks/useUiContext';
import AnonymousService from '../../services/AnonymousService';
import classes from './styles.module.scss';

export default function DropOff({ show, jobApplicationId }) {
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { showModal, showUnknownErrorModal } = useUiContext();

  const { t } = useTranslation();

  const navigate = useNavigate();

  // Disable body scroll
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [show]);

  const validationSchema = useMemo(
    () =>
      yup.object({
        leaveReason: yup.string().trim().required(t('common.requiredField')),
        email: yup
          .string()
          .trim()
          .email(t('pages.DropOffPage.emailFormatIncorrect')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      leaveReason: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await AnonymousService.createDropOff({
          reason: values.leaveReason,
          email: values.email,
          jobApplicationId,
        });
        showModal({
          title: t('pages.DropOffPage.thankYouTitle'),
          text: t('pages.DropOffPage.thankYouText'),
          noCancel: true,
          onConfirm: () => {
            navigate('/', { replace: true });
          },
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      }
    },
  });

  const leaveReasons = [
    {
      value: 'It does not fulfill my expectations',
      label: t('pages.DropOffPage.leaveReasons.itDoesNotFulfillMyExpectations'),
    },
    {
      value: 'Not interested',
      label: t('pages.DropOffPage.leaveReasons.notInterested'),
    },
    {
      value: 'Just browsing',
      label: t('pages.DropOffPage.leaveReasons.justBrowsing'),
    },
  ];

  if (!show) {
    return null;
  }

  return (
    <div className={classes.DropOff}>
      <Layout>
        <div className={classes.container}>
          <div className={classes.navHeaderContainer}>
            <div className={classes.innerContainer}>
              <NavHeader backIcon />
            </div>
          </div>
          <form className={classes.row} onSubmit={formik.handleSubmit}>
            <div className={classes.col}>
              <h2 className={classes.colHeading}>
                {t('pages.DropOffPage.sadToSeeYouGo')}
              </h2>
              <Dropdown
                height={isTabletOrMobile ? 60 : 64}
                label={t('pages.DropOffPage.whyAreYouLeaving')}
                placeholder={t('pages.DropOffPage.pleaseSelectReason')}
                options={leaveReasons}
                name="leaveReason"
                setFieldValue={formik.setFieldValue}
                value={formik.values.leaveReason}
              />
            </div>
            <div className={classes.col}>
              <h2 className={classes.colHeading}>
                {t('pages.DropOffPage.sendFutureJobOpenings')}
              </h2>
              <TextInput
                height={isTabletOrMobile ? 60 : 64}
                label={t('pages.DropOffPage.leaveEmailForJobOpenings')}
                name="email"
                placeholder={t('pages.DropOffPage.emailPlaceholder')}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.email}
                touched={hasTriedToSubmit}
                type="email"
                noShadow
              />
            </div>
          </form>
          <div className={classes.buttonContainer}>
            <Button
              height={isTabletOrMobile ? 54 : 64}
              width={isTabletOrMobile ? '' : 444}
              style={{ fontSize: 24, fontWeight: 500 }}
              disabled={!formik.values.leaveReason}
              onClick={() => {
                setHasTriedToSubmit(true);
                formik.handleSubmit();
              }}
            >
              {t('common.submit')}
            </Button>
          </div>
        </div>
      </Layout>
    </div>
  );
}
