import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import PublicService from '../services/PublicService';
import UserService from '../services/UserService';
import useUiContext from './useUiContext';
import { setDate } from '../helpers/timezones';

export default function useUpdateProfileFromApplication(
  attachment,
  birthDate,
  city,
  education,
  jobs,
  isFresher,
  job
) {
  const { showModal, setIsFetching, showApplicationSuccessModal } =
    useUiContext();
  const { t } = useTranslation();

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const updateUserProlile = async (shouldUploadResume) => {
    const defaultUserData = { ...user?.userProfile };

    let docResume = defaultUserData?.userProfileFileResume;

    if (shouldUploadResume) {
      const uploadedResumeData = await PublicService.uploadFile({
        file: attachment,
      });
      docResume = {
        fileName: uploadedResumeData.displayName,
        url: uploadedResumeData.uri,
        mimeType: uploadedResumeData.mimeType,
      };
    }

    await UserService.updateMe({
      ...defaultUserData,
      birthDate: setDate(birthDate) || defaultUserData.birthDate,
      city: defaultUserData.city || city,
      userProfileGraduation: defaultUserData?.userProfileGraduation || {
        id: education.value,
        name: education.label,
      },
      userProfileExperiences:
        defaultUserData?.userProfileExperiences ||
        jobs
          ?.filter((jb) => jb.jobTitle || jb.companyName)
          .map((jb) => ({
            jobTitle: jb.jobTitle,
            company: jb.companyName,
            startDate: jb.startDate,
            endDate: jb.endDate,
          })),
      userProfileFileResume:
        defaultUserData?.userProfileFileResume || docResume,
      isFresher,
    });

    if (shouldUploadResume) {
      showModal({
        title: t('common.success'),
        text: t('pages.CompleteApplicationPage.resumeSaved'),
        onCancel: () =>
          showApplicationSuccessModal({
            userName: user?.userProfile?.fullName,
            jobTitle: job?.jobTitle,
          }),
      });
    } else {
      /*    showModal({
        title: t('common.success'),
        text: t('pages.CompleteApplicationPage.formHasBeenUploaded'),
      }); */

      showApplicationSuccessModal({
        userName: user?.userProfile?.fullName,
        jobTitle: job?.jobTitle,
      });
    }
  };

  const updateUserProfileFromJobApplication = async () => {
    const docResume = user?.userProfile?.userProfileFileResume;

    if (docResume) {
      updateUserProlile(false);
      return;
    }

    showModal({
      title: t('pages.CompleteApplicationPage.Save Resume'),
      text: t('pages.CompleteApplicationPage.confirmResumeSave'),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          updateUserProlile(true);
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      },
      onCancel: async () => {
        try {
          setIsFetching(true);
          updateUserProlile(false);
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      },
    });
  };

  return updateUserProfileFromJobApplication;
}
