import React from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Button from '../../Button';
import TextInput from '../../TextInput';
import PhoneSelector from '../../PhoneSelector';

import classes from './styles.module.scss';

export default function ProfileForm({ formik, hasTriedToSubmit, onSubmit }) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const isContinueButtonDisabled =
    !formik.values.firstName ||
    !formik.values.lastName ||
    !formik.values.phone ||
    formik.isSubmitting;

  return (
    <div className={classes.ProfileForm}>
      <h1>{t('components.SignIn.ProfileForm.newUserHeading')}</h1>
      <p>{t('components.SignIn.ProfileForm.newUserDescription')}</p>
      <form>
        <TextInput
          label={t('components.SignIn.ProfileForm.firstNameLabel')}
          placeholder={t('components.SignIn.ProfileForm.firstNamePlaceholder')}
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={hasTriedToSubmit}
          error={formik.errors.firstName}
        />
        <TextInput
          label={t('components.SignIn.ProfileForm.lastNameLabel')}
          placeholder={t('components.SignIn.ProfileForm.lastNamePlaceholder')}
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={hasTriedToSubmit}
          error={formik.errors.lastName}
        />
        <PhoneSelector
          label={t('components.SignIn.ProfileForm.phoneNumberLabel')}
          placeholder={t(
            'components.SignIn.ProfileForm.phoneNumberPlaceholder'
          )}
          value={formik.values.phone}
          onChange={formik.handleChange}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={hasTriedToSubmit}
          error={formik.errors.phone}
          name="phone"
          type="phone"
        />
      </form>
      <div className={classes.continueButtonContainer}>
        <Button
          height={isTabletOrMobile ? 54 : 64}
          style={{ fontSize: 24, lineHeight: '30px' }}
          onClick={onSubmit}
          disabled={isContinueButtonDisabled}
        >
          {t('common.continue')}
        </Button>
      </div>
    </div>
  );
}
