import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MessageWithButton from '../MessageWithButton';

import classes from './styles.module.scss';

export default function RejectionMessage({ status }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  let message;

  if (
    status === 'New' ||
    status === 'Screen' ||
    status === 'Offer' ||
    status === 'Onboard' ||
    status === 'Interview'
  ) {
    message = (
      <p>
        {t(
          'components.MyDashboard.JobApplicationInfo.RejectionMessage.message1'
        )}{' '}
        <br />
        <br />{' '}
        {t(
          'components.MyDashboard.JobApplicationInfo.RejectionMessage.message2'
        )}
      </p>
    );
  }

  /*  if (status === 'Interview') {
    message = (
      <p>
        Thanks for sharing that information. <br />
        <br /> Unfortunate we could not work together on this role. Please
        browse through other jobs in our portal to apply quickly if you may be
        interested.
      </p>
    );
  } */

  return (
    <div className={classes.RejectionMessage}>
      <MessageWithButton
        message={message}
        buttonLabel={t(
          'components.MyDashboard.JobApplicationInfo.RejectionMessage.browseJobs'
        )}
        onButtonClick={() => navigate('/jobs')}
      />
    </div>
  );
}
