import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Button from '../../Button';
import DocUploader from './DocUploader';
import WorkExperienceDropdown from './WorkExperienceDropdown';
import EducationDropdown from './EducationDropdown';
import LocationDropdown from './LocationDropdown';
import CalendarDropdown from './CalendarDropdown';

import classes from './styles.module.scss';
import UserService from '../../../services/UserService';

const isWorkExperienceValid = (hasWorkExperience, jobs, isFresher) => {
  if (hasWorkExperience === false && isFresher) {
    return true;
  }

  if (hasWorkExperience === true) {
    return jobs.every(
      (job) =>
        job.jobTitle &&
        job.companyName &&
        job.startDate &&
        (!job.endDate || job.endDate >= job.startDate)
    );
  }

  return false;
};

export default function AdditionalInfoForm({
  birthDate,
  setBirthDate,
  city,
  setCity,
  education,
  setEducation,
  hasWorkExperience,
  setHasWorkExperience,
  isFresher,
  setIsFresher,
  jobs,
  setJobs,
  submitAdditionalForm,
  file,
  setFile,
  isFileRequired,
}) {
  const [isTryingToSubmit, setIsTryingToSubmit] = useState(false);

  const { t } = useTranslation();

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  useEffect(() => {
    setCity(user?.userProfile?.city);
    setBirthDate(user?.userProfile?.birthDate);
    setEducation({
      value: user?.userProfile?.userProfileGraduation?.id,
      label: user?.userProfile?.userProfileGraduation?.name,
    });
    setJobs(
      user?.userProfile?.userProfileExperiences.map((exp, index) => ({
        id: index,
        jobTitle: exp.jobTitle,
        companyName: exp.company,
        startDate: exp.startDate,
        endDate: exp.endDate,
      }))
    );
    setFile(user?.userProfile?.userProfileFileResume);

    if (user?.userProfile?.userProfileExperiences.length) {
      setHasWorkExperience(true);
    } else {
      setHasWorkExperience(false);
    }
    setIsFresher(user?.userProfile?.isFresher);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const isSubmitButtonDisabled =
    !birthDate ||
    !city ||
    !education ||
    !isWorkExperienceValid(hasWorkExperience, jobs, isFresher) ||
    (isFileRequired && !file) ||
    isTryingToSubmit;

  return (
    <div className={classes.AdditionalInfoForm}>
      <h1 className={classes.title}>
        {t('components.RecordVideo.AdditionalInfoForm.additionalInfoFormTitle')}
      </h1>
      <div className={classes.row}>
        <div className={classes.col}>
          <CalendarDropdown date={birthDate} setDate={setBirthDate} />
        </div>
        <div className={classes.col}>
          <LocationDropdown location={city} setLocation={setCity} />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.col}>
          <EducationDropdown
            education={education}
            setEducation={setEducation}
          />
        </div>
        <div className={classes.col}>
          <WorkExperienceDropdown
            hasWorkExperience={hasWorkExperience}
            setHasWorkExperience={setHasWorkExperience}
            isFresher={isFresher}
            setIsFresher={setIsFresher}
            jobs={jobs}
            setJobs={setJobs}
          />
        </div>
      </div>
      <div className={classNames(classes.row, classes.docUploaderContainer)}>
        <DocUploader
          file={file}
          setFile={setFile}
          isFileRequired={isFileRequired}
        />
      </div>
      <div className={classNames(classes.row, classes.buttonContainer)}>
        <Button
          height={54}
          style={{ fontSize: isTabletOrMobile ? 16 : 24 }}
          disabled={isSubmitButtonDisabled}
          onClick={() => {
            setIsTryingToSubmit(true);
            submitAdditionalForm();
          }}
        >
          {t('common.submit')}
        </Button>
      </div>
    </div>
  );
}
