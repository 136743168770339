/* eslint-disable no-shadow */
/* eslint-disable no-unreachable */
/* eslint-disable consistent-return */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';

import useSound from 'use-sound';
import { useMediaQuery } from 'react-responsive';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import DropOff from '../../components/DropOff';
import VideoUploadSuccess from '../../components/RecordVideo/MobileScreens/VideoUploadSuccess';
import UploadInProgress from '../../components/RecordVideo/MobileScreens/UploadInProgress';
import ReadyToGo from '../../components/RecordVideo/MobileScreens/ReadyToGo';
import FullScreenModal from '../../modals/FullScreenModal';
import TakePictureModal from '../../modals/TakePictureModal';
import RecordVideoProgress from '../../components/RecordVideo/RecordVideoProgress';
import Video from '../../components/RecordVideo/Video';
import RecordVideoControls from '../../components/RecordVideo/RecordVideoControls';
import NavHeader from '../../components/NavHeader';
import ReviewVideos from '../../components/RecordVideo/ReviewVideos';
import RecordVideoProgressMobile from '../../components/RecordVideo/RecordVideoProgressMobile';
import VrQuestionModal from '../../modals/VrQuestionModal';
import FinishRecordModal from '../../modals/FinishRecordModal';
import AdditionalInfoForm from '../../components/RecordVideo/AdditionalInfoForm';
import BlockRecordingOnLandscape from '../../components/RecordVideo/BlockRecordingOnLandscape';

import useBlocker from '../../hooks/useBlocker';
import useUpdateProfileFromApplication from '../../hooks/useUpdateProfileFromApplication';
import useLogoutBlockedUser from '../../hooks/useLogoutBlockedUser';
import doubleBeep from '../../assets/sounds/double-beep.mp3';
import shortBeep from '../../assets/sounds/short-beep.mp3';
import { UiContext } from '../../context/UiContext';
import PublicService from '../../services/PublicService';
import AnonymousService from '../../services/AnonymousService';
import classes from './styles.module.scss';
import { setDate } from '../../helpers/timezones';

function isChrome() {
  // Get the user agent string
  const userAgent = navigator.userAgent.toLowerCase();

  // Check if the user agent contains "chrome" and does not contain "edge" or "opr" (Opera)
  const isChromium = userAgent.indexOf('chrome') > -1;
  const isEdge = userAgent.indexOf('edge') > -1;
  const isOpera = userAgent.indexOf('opr') > -1;

  // Return true if it's Chrome and not Edge or Opera
  return isChromium && !isEdge && !isOpera;
}

let chunks;
let facingMode = 'user';

export default function RecordVideoPage() {
  const [file, setFile] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [countdownTimerValue, setCountdownTimerValue] = useState('3');
  const [videoPlayerOverlayMode, setVideoPlayerOverlayMode] =
    useState('prePhoto');
  const [isVideoRecording, setIsVideoRecording] = useState(false);
  const [isViewSampleVisible, setIsViewSampleVisible] = useState(false);
  const [isVideoRecordingPaused, setIsVideoRecrodingPaused] = useState(false);
  const [recordedMedia, setRecordedMedia] = useState([]);
  const [image, setImage] = useState(null);
  const [steps, setSteps] = useState([]);
  const [vrqIds, setVrqIds] = useState([]);
  const [isTakePictureModalVisible, setIsTakePictureModalVisible] =
    useState(false);
  const [isVrQuestionModalVisible, setIsVrQuestionModalVisible] =
    useState(false);
  const [isFinishRecordModalVisible, setIsFinishRecordModalVisible] =
    useState(false);
  const [birthDate, setBirthDate] = useState(null);
  const [city, setCity] = useState(null);
  const [education, setEducation] = useState(null);
  const [hasWorkExperience, setHasWorkExperience] = useState();
  const [isFresher, setIsFresher] = useState(false);
  const [jobs, setJobs] = useState([
    {
      id: Math.random(),
      jobTitle: '',
      companyName: '',
      startDate: new Date(),
      endDate: new Date(),
    },
  ]);
  const [application, setApplication] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [isFullScreenModalVisible, setIsFullScreenModalVisible] =
    useState(false);
  const [isMediaUploading, setIsMediaUploading] = useState(false);
  const [isMediaUploaded, setIsMediaUploaded] = useState(false);
  const [mediaUploadProgress, setMediaUploadProgress] = useState([]);
  const [totalMediaSize, setTotalMediaSize] = useState(0);
  const [coverFileName, setCoverFileName] = useState('');
  // const [isApplicationCompleted, setIsApplicationCompleted] = useState(false);
  const [videoStreamResolution, setVideoStreamResolution] = useState({
    width: 0,
    height: 0,
  });
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoRecordingStartTime, setVideoRecordingStartTime] = useState(0);
  const [recordPauses, setRecordPauses] = useState([]);
  const [isDropOffVisible, setIsDropOffVisible] = useState(false);
  const [isJobApplicationSubmited, setIsJobApplicationSubmited] =
    useState(false);

  const streamRef = useRef();
  const videoPlayerRef = useRef();
  const mediaRecorderRef = useRef();
  const canvasRef = useRef();
  const videoPreviewRef = useRef();
  const fileInputRef = useRef();
  const isFlippingCamera = useRef(false);

  const jobApplicationId = useRef();

  const { isUserProfileFilled, previousResume, setIsFetching } =
    useContext(UiContext);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const { jobId } = useParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const shouldUsePreviousResume = !!searchParams.get('previous');
  const invitedJobApplicationId = searchParams.get('jobApplicationId');

  useEffect(() => {
    if (invitedJobApplicationId) {
      setApplication({ id: invitedJobApplicationId });
    }
  }, [invitedJobApplicationId]);

  const [playShortBeep] = useSound(shortBeep);
  const [playDoubleBeep] = useSound(doubleBeep);

  const { data: job } = useQuery({
    queryKey: ['job', jobId],
    queryFn: () => AnonymousService.getJobById({ jobId }),
  });

  const updateUserProfileFromJobApplication = useUpdateProfileFromApplication(
    attachment,
    birthDate,
    city,
    education,
    jobs,
    isFresher,
    job
  );

  const { checkAndLogout } = useLogoutBlockedUser();

  const setIsBlocking = useBlocker(() => {
    setIsDropOffVisible(true);
    setIsBlocking(false);
  });

  // Hide drop off form on page unload
  useEffect(() => {
    return () => {
      setIsDropOffVisible(false);
    };
  }, []);

  // Disable blocker after has submitted application
  useEffect(() => {
    if (isJobApplicationSubmited) {
      setIsBlocking(false);
    }
  }, [isJobApplicationSubmited, setIsBlocking]);

  // Create draft job application on page load if not invited
  useEffect(() => {
    const createDraftJobApplication = async () => {
      try {
        return await PublicService.createJobApplication({
          jobPostId: jobId,
          status: 'Draft',
        });
      } catch (error) {
        console.log(error);
        return null;
      }
    };

    if (jobId && !invitedJobApplicationId) {
      createDraftJobApplication().then((jobApp) => {
        jobApplicationId.current = jobApp?.id;
        setApplication(jobApp);
      });
    }
  }, [jobId, invitedJobApplicationId]);

  // Record job application start to the backend
  useEffect(() => {
    const startApplication = async () => {
      try {
        await PublicService.startJobApplication({
          jobId,
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (jobId) {
      startApplication();
    }
  }, [jobId]);

  // Set component for the previous resume
  useEffect(() => {
    if (shouldUsePreviousResume && previousResume?.jobId === jobId) {
      if (previousResume.type === 'doc') {
        setAttachment(previousResume.doc);
      } else if (previousResume.type === 'video') {
        const infoStepIndex = steps.findIndex(
          (step) => step.name === 'Additional Information'
        );
        setCurrentStep(infoStepIndex + 1);
      }
    }
  }, [jobId, previousResume, shouldUsePreviousResume, steps]);

  useEffect(() => {
    if (isUserProfileFilled === false) {
      navigate('/', { replace: true });
    }
  }, [isUserProfileFilled, navigate]);

  useEffect(() => {
    if (job) {
      let stps = [{ name: t('pages.RecordVideoPage.picture') }];
      // eslint-disable-next-line no-shadow
      const vrqIds = [];
      if (job.videoResumeQuestions.length) {
        job.videoResumeQuestions
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .forEach((vrq) => {
            stps.push({
              name: vrq.title,
              duration: vrq.duration,
              vrqId: vrq.id,
              question: vrq.question,
            });
            vrqIds.push(vrq.id);
          });
      }
      stps = [
        ...stps,
        { name: t('pages.RecordVideoPage.reviewAndUpload') },
        { name: t('pages.RecordVideoPage.additionalInformation') },
        { name: t('pages.RecordVideoPage.apply') },
      ];
      setSteps(stps);
      setVrqIds(vrqIds);
    }
  }, [job, t]);

  const startCameraMediaRecorder = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: {
          height: 320,
          width: 736,
          facingMode,
        },
      })
      .then((stream) => {
        const isFirefox =
          navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        const streamSettings = stream.getVideoTracks()?.[0]?.getSettings();
        setVideoStreamResolution({
          width: isFirefox ? streamSettings.height : streamSettings.width,
          height: isFirefox ? streamSettings.width : streamSettings.height,
          //   height: 736,
          //   width: 320,
        });

        if (!videoPlayerRef.current) {
          return;
        }

        streamRef.current = stream;
        videoPlayerRef.current.srcObject = stream;
        videoPlayerRef.current.onloadedmetadata = () => {
          if (!videoPlayerRef.current) {
            return;
          }
          videoPlayerRef.current.play();
        };

        if (isSafari) {
          mediaRecorderRef.current = new MediaRecorder(stream, {
            mimeType: 'video/mp4',
          });
        } else if (isChrome()) {
          mediaRecorderRef.current = new MediaRecorder(stream, {
            mimeType: 'video/webm; codecs=vp9',
          });
        } else {
          mediaRecorderRef.current = new MediaRecorder(stream);
        }

        chunks = [];

        isFlippingCamera.current = false;

        mediaRecorderRef.current.addEventListener('dataavailable', (e) => {
          if (e.data.size > 0) {
            chunks.push(e.data);
          }
        });

        mediaRecorderRef.current.addEventListener('stop', () => {
          stream.getTracks().forEach((track) => {
            track.stop();
          });

          if (chunks.length) {
            const blob = new Blob(chunks, {
              type: isSafari ? 'video/mp4' : 'video/webm',
            });

            setFile(blob);
          }
        });
      })
      .catch((error) => {
        console.log(error);

        // On some devices and browsers it is impossible to start mediarecorder again when flipping camera without stopping all the tracks
        // So if starting fails we will try to stop all the tracks and start again
        if (isFlippingCamera.current) {
          streamRef.current.getTracks().forEach((track) => {
            track.stop();
          });

          startCameraMediaRecorder();
          isFlippingCamera.current = false;
        }
      });
  }, [isSafari]);

  const takePhoto = async () => {
    canvasRef.current
      .getContext('2d')
      .drawImage(
        videoPlayerRef.current,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

    const photo = canvasRef.current.toDataURL('image/jpeg');
    fetch(photo)
      .then((res) => res.blob())
      .then((blob) => {
        setImage(blob);
        mediaRecorderRef.current.stop();
      })
      .catch((error) => console.log(error));
  };

  const startVideoRecordingOnMobile = () => {
    setIsVrQuestionModalVisible(true);
  };

  const startVideoRecording = () => {
    setVideoPlayerOverlayMode('startingRecord');

    setTimeout(() => {
      setCountdownTimerValue(2);
    }, 1000);

    setTimeout(() => {
      setCountdownTimerValue(1);
    }, 2000);

    setTimeout(() => {
      setCountdownTimerValue('GO');
      playShortBeep();
    }, 3000);

    setTimeout(() => {
      setVideoPlayerOverlayMode('recording');
      setCountdownTimerValue(3);
      setIsVideoRecording(true);
      mediaRecorderRef.current?.start();
      setVideoRecordingStartTime(Date.now());
    }, 4000);
  };

  const stopVideoRecording = async () => {
    setVideoPlayerOverlayMode('preRecord');
    setIsVideoRecording(false);
    setIsVideoRecrodingPaused(false);
    mediaRecorderRef.current?.stop();
    playDoubleBeep();

    let duration = (Date.now() - videoRecordingStartTime) / 1000;

    if (recordPauses.length) {
      const totalPausedTime = recordPauses.reduce((acc, curVal) => {
        if (curVal.end) {
          return acc + (curVal.end - curVal.start);
        }
        return acc + (Date.now() - curVal.start);
      }, 0);

      duration -= totalPausedTime / 1000;
    }

    setVideoDuration(duration);

    if (isTabletOrMobile) {
      setIsFinishRecordModalVisible(true);
    }
  };

  const pauseVideoRecording = useCallback(() => {
    mediaRecorderRef.current.pause();
    setIsVideoRecrodingPaused(true);
    setRecordPauses((prevPauses) => [...prevPauses, { start: Date.now() }]);
  }, []);

  const resumeVideoRecording = () => {
    mediaRecorderRef.current.resume();
    setIsVideoRecrodingPaused(false);
    if (recordPauses.length) {
      setRecordPauses((prevPauses) => {
        const lastPause = prevPauses.at(-1);

        if (!lastPause.end) {
          const updatedLastPause = { start: lastPause.start, end: Date.now() };
          return [...prevPauses.slice(0, -1), updatedLastPause];
        }
        return [...prevPauses];
      });
    }
  };

  const retakePhoto = () => {
    fileInputRef.current.value = '';
    setImage(null);
    startCameraMediaRecorder();
  };

  const usePhoto = () => {
    setRecordedMedia([image]);
    setVideoPlayerOverlayMode('preRecord');
    setCurrentStep(2);
    setImage(null);
    startCameraMediaRecorder();
    if (isTabletOrMobile) {
      setIsFullScreenModalVisible(true);
    }
  };

  const retakeVideo = () => {
    const isReview = steps[currentStep - 1]?.name === 'Review & Upload';

    if (isReview) {
      setCurrentStep((prevStep) => prevStep - 1);
      setRecordedMedia((prevMedia) => prevMedia.slice(0, -1));
    }

    setFile(null);
    setVideoDuration(0);
    setRecordPauses([]);
    setVideoPlayerOverlayMode('preRecord');
    startCameraMediaRecorder();
  };

  const restartRecording = useCallback(() => {
    setFile(null);
    setRecordedMedia([]);
    setCurrentStep(1);
    setVideoPlayerOverlayMode('prePhoto');
    startCameraMediaRecorder();
    setRecordPauses([]);
    setVideoDuration(0);
  }, [startCameraMediaRecorder]);

  const useVideo = () => {
    setRecordedMedia((prevMedia) => [...prevMedia, file]);
    setFile(null);
    setVideoDuration(0);
    setRecordPauses([]);
    setVideoPlayerOverlayMode('preRecord');
    startCameraMediaRecorder();
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const showFileBrowser = () => {
    fileInputRef.current?.click();
  };

  const flipMobileCamera = () => {
    if (videoPlayerOverlayMode === 'startingRecord') {
      return;
    }

    isFlippingCamera.current = true;

    facingMode = facingMode === 'user' ? 'environment' : 'user';

    startCameraMediaRecorder();
  };

  const closeFullScreenModal = () => {
    setIsFullScreenModalVisible(false);
  };

  const uploadMedia = useCallback(async () => {
    setIsFetching(true);

    await checkAndLogout();

    const updateMediaUploadProgress = (index) => {
      return (progressEvent) => {
        setMediaUploadProgress((prevState) =>
          prevState.map((mediaProgress, i) => {
            if (i === index) {
              return progressEvent.loaded;
            }
            return mediaProgress;
          })
        );
      };
    };

    const uploadVideo = async (video, jobApplicationId, vrqId, index) => {
      const uploadedVideo = await PublicService.uploadVideo(
        video,
        updateMediaUploadProgress(index),
        isSafari
      );

      return PublicService.createJobApplicationVideo({
        jobApplicationId,
        url: uploadedVideo.uri,
        fileName: uploadedVideo.fileName,
        mimeType: uploadedVideo.mimeType,
        videoResumeQuestionId: vrqId,
      });
    };

    try {
      setIsMediaUploading(true);

      //  return;

      if (isTabletOrMobile) {
        setIsFullScreenModalVisible(true);
      }

      jobApplicationId.current = application.id;

      setTotalMediaSize(
        recordedMedia.reduce((acc, curVal) => acc + curVal.size, 0)
      );
      setMediaUploadProgress(new Array(recordedMedia.length).fill(0));
      const uploadPromises = recordedMedia.slice(1).map((video, index) => {
        return uploadVideo(
          video,
          jobApplicationId.current,
          vrqIds[index],
          index + 1
        );
      });

      await Promise.all(uploadPromises);

      const uploadedImage = await PublicService.uploadImage(
        recordedMedia[0],
        updateMediaUploadProgress(0)
      );

      await PublicService.updateJobApplication({
        jobApplicationId: jobApplicationId.current,
        coverFileName: uploadedImage.fileName,
        jobPostId: job.id,
      });

      setCoverFileName(uploadedImage.fileName);

      setCurrentStep((prevStep) => prevStep + 1);
      setIsMediaUploading(false);
      setIsMediaUploaded(true);
    } catch (error) {
      console.log(error);
      setIsMediaUploading(false);
      if (isTabletOrMobile) {
        setIsFullScreenModalVisible(false);
      }
    } finally {
      setIsFetching(false);
    }
  }, [
    isSafari,
    isTabletOrMobile,
    job?.id,
    recordedMedia,
    setIsFetching,
    vrqIds,
    application,
  ]);

  const submitAdditionalForm = async () => {
    setIsFetching(true);

    // setIsApplicationCompleted(true);

    let jobApplication = application;

    if (!jobApplication) {
      jobApplication = await PublicService.createJobApplication({
        jobPostId: job.id,
      });
      setApplication(jobApplication);
    }

    try {
      if (hasWorkExperience) {
        const promises = jobs.map((job) => {
          return PublicService.createJobApplicationExp({
            jobApplicationId: jobApplication.id,
            jobTitle: job.jobTitle,
            company: job.companyName,
            startDate: job.startDate,
            endDate: job.endDate,
          });
        });
        await Promise.all(promises);
      }

      if (attachment) {
        await PublicService.uploadJobApplicationAttach({
          file: attachment,
          jobApplicationId: jobApplication.id,
        });
      }

      if (shouldUsePreviousResume && previousResume.type === 'video') {
        const videoPromises = previousResume.videos.map((vid, index) =>
          PublicService.createJobApplicationVideo({
            jobApplicationId: jobApplication.id,
            url: vid.url,
            fileName: vid.fileName,
            mimeType: vid.mimeType,
            videoResumeQuestionId: vrqIds[index],
          })
        );

        await Promise.all(videoPromises);
      }

      await PublicService.updateJobApplication({
        jobApplicationId: jobApplication.id,
        graduationId: education.value,
        applicantLocation: city,
        birthDate: setDate(birthDate),
        jobPostId: job.id,
        status: 'New',
        coverFileName,
      });

      await PublicService.finishedJobApplication({
        jobId,
      });

      setIsJobApplicationSubmited(true);

      updateUserProfileFromJobApplication();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    startCameraMediaRecorder();
  }, [startCameraMediaRecorder]);

  useEffect(() => {
    if (isTabletOrMobile) {
      setIsTakePictureModalVisible(true);
    }
  }, [isTabletOrMobile]);

  // Clenaup media recorder
  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
    };
  }, []);

  let mainComponent;

  if (
    steps[currentStep - 1]?.name ===
    t('pages.RecordVideoPage.additionalInformation')
  ) {
    mainComponent = (
      <AdditionalInfoForm
        birthDate={birthDate}
        setBirthDate={setBirthDate}
        city={city}
        setCity={setCity}
        education={education}
        setEducation={setEducation}
        hasWorkExperience={hasWorkExperience}
        setHasWorkExperience={setHasWorkExperience}
        isFresher={isFresher}
        setIsFresher={setIsFresher}
        jobs={jobs}
        setJobs={setJobs}
        submitAdditionalForm={submitAdditionalForm}
        file={attachment}
        setFile={setAttachment}
        isFileRequired={
          job?.applicationType === 'DocumentOnly' ||
          job?.applicationType === 'VideoAndDocument'
        }
      />
    );
  } else {
    mainComponent = (
      <Video
        videoDuration={videoDuration}
        setVideoDuration={setVideoDuration}
        step={steps[currentStep - 1]}
        file={file}
        videoPlayerRef={videoPlayerRef}
        mode={videoPlayerOverlayMode}
        countdownTimerValue={countdownTimerValue}
        startVideoRecording={
          isTabletOrMobile ? startVideoRecordingOnMobile : startVideoRecording
        }
        stopVideoRecording={stopVideoRecording}
        isVideoRecording={isVideoRecording}
        isViewSampleVisible={isViewSampleVisible}
        setIsViewSampleVisible={setIsViewSampleVisible}
        pauseVideoRecording={pauseVideoRecording}
        isVideoRecordingPaused={isVideoRecordingPaused}
        resumeVideoRecording={resumeVideoRecording}
        retakeVideo={retakeVideo}
        useVideo={useVideo}
        canvasRef={canvasRef}
        takePhoto={takePhoto}
        image={image}
        retakePhoto={retakePhoto}
        usePhoto={usePhoto}
        videoPreviewRef={videoPreviewRef}
        showFileBrowser={showFileBrowser}
        flipMobileCamera={flipMobileCamera}
        videoStreamResolution={videoStreamResolution}
        isSafari={isSafari}
      />
    );
  }

  if (
    steps[currentStep - 1]?.name === t('pages.RecordVideoPage.reviewAndUpload')
  ) {
    mainComponent = (
      <ReviewVideos
        recordedMedia={recordedMedia.slice(1)}
        restartRecording={restartRecording}
        uploadMedia={uploadMedia}
      />
    );
  }

  let fullScreenModalContent = (
    <ReadyToGo
      questions={job?.videoResumeQuestions}
      onClose={closeFullScreenModal}
    />
  );

  if (isMediaUploading) {
    fullScreenModalContent = (
      <UploadInProgress
        totalMediaSize={totalMediaSize}
        mediaUploadProgress={mediaUploadProgress}
      />
    );
  }

  if (isMediaUploaded) {
    fullScreenModalContent = (
      <VideoUploadSuccess onClose={closeFullScreenModal} />
    );
  }

  const leftJobApplication = async () => {
    try {
      await PublicService.leftJobApplication({
        jobId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.RecordVideoPage}>
      <BlockRecordingOnLandscape pauseVideoRecording={pauseVideoRecording} />
      <div className={classes.header}>
        <NavHeader
          recordVideoPage
          top={isTabletOrMobile ? 19 : 34}
          leftJobApplication={leftJobApplication}
        />
      </div>
      <div className={classes.container}>
        {isTabletOrMobile ? (
          <RecordVideoProgressMobile
            activeStep={currentStep}
            steps={steps.slice(0, steps.length - 1)}
            isViewSampleVisible={isViewSampleVisible}
            setIsTakePictureModalVisible={setIsTakePictureModalVisible}
            setIsVrQuestionModalVisible={setIsVrQuestionModalVisible}
            isRecording={isVideoRecording}
          />
        ) : (
          <RecordVideoProgress activeStep={currentStep} steps={steps} />
        )}
        {mainComponent}
        {!isTabletOrMobile &&
          steps[currentStep - 1]?.name !==
            t('pages.RecordVideoPage.additionalInformation') && (
            <RecordVideoControls
              isVideoRecordingPaused={isVideoRecordingPaused}
              currentStep={currentStep}
              hasImage={!!image}
              hasVideo={!!file}
              takePhoto={takePhoto}
              retakePhoto={retakePhoto}
              usePhoto={usePhoto}
              steps={steps}
              startVideoRecording={startVideoRecording}
              isVideoRecording={isVideoRecording}
              stopVideoRecording={stopVideoRecording}
              retakeVideo={retakeVideo}
              useVideo={useVideo}
              uploadMedia={uploadMedia}
              isFileRequired={job?.applicationType === 'VideoAndDocument'}
              restartRecording={restartRecording}
              resumeVideoRecording={resumeVideoRecording}
            />
          )}
      </div>
      <TakePictureModal
        show={isTakePictureModalVisible}
        handleClose={() => setIsTakePictureModalVisible(false)}
      />
      <VrQuestionModal
        show={isVrQuestionModalVisible}
        handleClose={() => setIsVrQuestionModalVisible(false)}
        startVideoRecording={startVideoRecording}
        time={steps[currentStep - 1]?.duration}
        question={job?.videoResumeQuestions[currentStep - 2]?.question}
      />
      <FinishRecordModal
        show={isFinishRecordModalVisible}
        handleClose={() => setIsFinishRecordModalVisible(false)}
        videosLeft={vrqIds.length - (currentStep - 1)}
        useVideo={useVideo}
        file={file}
        time={steps[currentStep - 1]?.duration}
        retakeVideo={retakeVideo}
        uploadMedia={uploadMedia}
        videoDuration={videoDuration}
      />
      <input
        style={{ display: 'none' }}
        type="file"
        value={null}
        onChange={(event) => {
          setImage(event.target.files[0]);
        }}
        ref={fileInputRef}
        accept="image/png, image/jpeg"
      />
      {isTabletOrMobile && isFullScreenModalVisible && (
        <FullScreenModal>{fullScreenModalContent}</FullScreenModal>
      )}
      <DropOff
        show={isDropOffVisible}
        handleClose={() => {
          setIsDropOffVisible(false);
        }}
        jobApplicationId={jobApplicationId.current}
        jobId={jobId}
      />
    </div>
  );
}
